import React from 'react';

import { css } from '@emotion/react';
import { graphql, HeadProps, PageProps } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';

import {
  Layout,
  Seo,
  ContentWrapper,
  Masthead,
  MainMasthead,
  SearchForm,
} from '@/components';

const title = 'Posts';
const description = '전체 포스트 목록을 살펴보세요.';

const PostsIndex = ({
  data,
}: PageProps<{
  background: ImageDataLike;
}>) => {
  const { background } = data;

  return (
    <Layout>
      <Masthead background={background}>
        <MainMasthead title={title} description={description} />
      </Masthead>

      <ContentWrapper
        component={'main'}
        css={css`
          padding-top: 3rem;
          padding-bottom: 3rem;
        `}
      >
        <SearchForm />
      </ContentWrapper>
    </Layout>
  );
};

export default PostsIndex;

export const backgroundQuery = graphql`
  query {
    background: file(relativePath: { eq: "Posts.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export const Head = ({ location }: HeadProps) => {
  return (
    <Seo title={title} description={description} path={location.pathname} />
  );
};
